import { Main } from '../components/Main'
import { getMeta } from '../helpers/getMeta'
import { PageWrapper } from '../components/PageWrapper'
import { useStoryblok } from '../lib/storyblok/hooks/useStoryblok'
import { GeneralError } from '../components/atoms/GeneralError'
import { getPageData } from '../helpers/getPageData'

export default function Custom404({ data, preview, error }) {
  const enableBridge = preview
  const story = useStoryblok(data?.story, enableBridge, data?.locale)

  if (preview && error) {
    return <GeneralError>{error}</GeneralError>
  }

  return (
    <Main
      data={{ ...data, story: story }}
      preview={preview}
      meta={getMeta({ data })}
    >
      <PageWrapper data={{ ...data, story: story }} preview={preview} />
    </Main>
  )
}

export async function getStaticProps({ locale, locales, preview = false }) {
  try {
    const slug = 'errors/page-not-found'
    const data = await getPageData({ slug, locale, locales, preview })

    return {
      props: {
        preview,
        slug,
        data,
      },
    }
  } catch (e) {
    return { props: { error: e, preview } }
  }
}
